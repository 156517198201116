@font-face {
  font-family: "and6";
  src: url("../font/and6/fonts/and6.eot?9rlm3v");
  src: url("../font/and6/fonts/and6.eot?9rlm3v#iefix") format("embedded-opentype"), url("../font/and6/fonts/and6.ttf?9rlm3v") format("truetype"), url("../font/and6/fonts/and6.woff?9rlm3v") format("woff"), url("../font/and6/fonts/and6.svg?9rlm3v#and6") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.sc-and6 {
  font-family: "and6" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sc-and6-video-cam:before {
  content: "\e941";
}

.sc-and6-bill:before {
  content: "\e935";
}

.sc-and6-club:before {
  content: "\e936";
}

.sc-and6-copy:before {
  content: "\e937";
}

.sc-and6-home:before {
  content: "\e938";
}

.sc-and6-new:before {
  content: "\e939";
}

.sc-and6-pencil:before {
  content: "\e93a";
}

.sc-and6-question:before {
  content: "\e93b";
}

.sc-and6-signal:before {
  content: "\e93c";
}

.sc-and6-sms:before {
  content: "\e93d";
}

.sc-and6-tour:before {
  content: "\e93e";
}

.sc-and6-vertical-dots:before {
  content: "\e93f";
}

.sc-and6-website:before {
  content: "\e940";
}

.sc-and6-ads:before {
  content: "\e900";
}

.sc-and6-alarm:before {
  content: "\e901";
}

.sc-and6-bold:before {
  content: "\e902";
}

.sc-and6-calendar:before {
  content: "\e903";
}

.sc-and6-chat:before {
  content: "\e904";
}

.sc-and6-check:before {
  content: "\e905";
}

.sc-and6-chevron-down:before {
  content: "\e906";
}

.sc-and6-chevron-left:before {
  content: "\e907";
}

.sc-and6-chevron-right:before {
  content: "\e908";
}

.sc-and6-chevron-up:before {
  content: "\e909";
}

.sc-and6-clock:before {
  content: "\e90a";
}

.sc-and6-close:before {
  content: "\e90b";
}

.sc-and6-comment:before {
  content: "\e90c";
}

.sc-and6-couple:before {
  content: "\e90d";
}

.sc-and6-create:before {
  content: "\e90e";
}

.sc-and6-filter:before {
  content: "\e90f";
}

.sc-and6-globe:before {
  content: "\e910";
}

.sc-and6-xl-view:before {
  content: "\e911";
}

.sc-and6-grid-view:before {
  content: "\e912";
}

.sc-and6-happy-hour:before {
  content: "\e913";
}

.sc-and6-heart-fill:before {
  content: "\e914";
}

.sc-and6-heart:before {
  content: "\e915";
}

.sc-and6-italic:before {
  content: "\e916";
}

.sc-and6-list-view:before {
  content: "\e917";
}

.sc-and6-mail:before {
  content: "\e918";
}

.sc-and6-man:before {
  content: "\e919";
}

.sc-and6-menu:before {
  content: "\e91a";
}

.sc-and6-open-hours:before {
  content: "\e91b";
}

.sc-and6-phone:before {
  content: "\e91c";
}

.sc-and6-photos:before {
  content: "\e91d";
}

.sc-and6-pin:before {
  content: "\e91e";
}

.sc-and6-play:before {
  content: "\e91f";
}

.sc-and6-price:before {
  content: "\e920";
}

.sc-and6-profile:before {
  content: "\e921";
}

.sc-and6-redo:before {
  content: "\e922";
}

.sc-and6-remove:before {
  content: "\e923";
}

.sc-and6-reply:before {
  content: "\e924";
}

.sc-and6-search:before {
  content: "\e925";
}

.sc-and6-send:before {
  content: "\e926";
}

.sc-and6-star-fill:before {
  content: "\e927";
}

.sc-and6-strikethrough:before {
  content: "\e928";
}

.sc-and6-telegram:before {
  content: "\e929";
}

.sc-and6-trans:before {
  content: "\e92a";
}

.sc-and6-underline:before {
  content: "\e92b";
}

.sc-and6-undo:before {
  content: "\e92c";
}

.sc-and6-update:before {
  content: "\e92d";
}

.sc-and6-upload:before {
  content: "\e92e";
}

.sc-and6-user:before {
  content: "\e92f";
}

.sc-and6-verified:before {
  content: "\e930";
}

.sc-and6-viber:before {
  content: "\e931";
}

.sc-and6-warning:before {
  content: "\e932";
}

.sc-and6-whatsapp:before {
  content: "\e933";
}

.sc-and6-woman:before {
  content: "\e934";
}

@font-face {
  font-family: "sceon";
  src: url("../font/sceon/fonts/sceon.eot?b83f37");
  src: url("../font/sceon/fonts/sceon.eot?b83f37#iefix") format("embedded-opentype"), url("../font/sceon/fonts/sceon.ttf?b83f37") format("truetype"), url("../font/sceon/fonts/sceon.woff?b83f37") format("woff"), url("../font/sceon/fonts/sceon.svg?b83f37#sceon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.sc {
  font-family: "sceon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sc-he-seeks:before {
  content: "\e9d3";
}

.sc-anonymous:before {
  content: "\e9ce";
}

.sc-level-basic:before {
  content: "\e9cf";
}

.sc-level-enought:before {
  content: "\e9d0";
}

.sc-level-exellent:before {
  content: "\e9d1";
}

.sc-level-good:before {
  content: "\e9d2";
}

.sc-signal:before {
  content: "\e9cd";
}

.sc-status-email:before {
  content: "\e9cb";
}

.sc-twint:before {
  content: "\e9cc";
}

.sc-online-support:before {
  content: "\e9ca";
}

.sc-happy-hour:before {
  content: "\e9c9";
}

.sc-mute:before {
  content: "\e9c6";
}

.sc-unmute:before {
  content: "\e9c7";
}

.sc-story:before {
  content: "\e9c8";
}

.sc-sugar:before {
  content: "\e9c5";
}

.sc-request:before {
  content: "\e9c3";
}

.sc-share:before {
  content: "\e9c4";
}

.sc-error:before {
  content: "\e9c2";
}

.sc-video-wtf:before {
  content: "\e9b6";
}

.sc-virtual-wtf:before {
  content: "\e9b7";
}

.sc-heart-wtf:before {
  content: "\e9b8";
}

.sc-arrowup-wtf:before {
  content: "\e9b9";
}

.sc-crown-wtf:before {
  content: "\e9ba";
}

.sc-diamond-wtf:before {
  content: "\e9bb";
}

.sc-verified-wtf:before {
  content: "\e9bc";
}

.sc-rating-wtf:before {
  content: "\e9bd";
}

.sc-camera-wtf:before {
  content: "\e9be";
}

.sc-next:before {
  content: "\e996";
}

.sc-back:before {
  content: "\e900";
}

.sc-arrow-back:before {
  content: "\e901";
}

.sc-double-arrow-back:before {
  content: "\e902";
}

.sc-double-arrow-next:before {
  content: "\e903";
}

.sc-arrow-next:before {
  content: "\e904";
}

.sc-arrow-down:before {
  content: "\e905";
}

.sc-arrow-up:before {
  content: "\e906";
}

.sc-add:before {
  content: "\e907";
}

.sc-add-btn:before {
  content: "\e994";
}

.sc-minus:before {
  content: "\e908";
}

.sc-cancel:before {
  content: "\e909";
}

.sc-check:before {
  content: "\e90a";
}

.sc-not-answered:before {
  content: "\e973";
}

.sc-avatar:before {
  content: "\e90b";
}

.sc-menu:before {
  content: "\e90c";
}

.sc-grid-view:before {
  content: "\e974";
}

.sc-extra-grid-view:before {
  content: "\e99f";
}

.sc-list-view:before {
  content: "\e975";
}

.sc-more:before {
  content: "\e90d";
}

.sc-filter:before {
  content: "\e958";
}

.sc-copy:before {
  content: "\e99b";
}

.sc-star:before {
  content: "\e90e";
}

.sc-star-half:before {
  content: "\e95f";
}

.sc-star-negative:before {
  content: "\e997";
}

.sc-star-negative-half:before {
  content: "\e998";
}

.sc-heart:before {
  content: "\e90f";
}

.sc-heart-half:before {
  content: "\e960";
}

.sc-heart-negative:before {
  content: "\e999";
}

.sc-heart-negative-half:before {
  content: "\e99a";
}

.sc-image:before {
  content: "\e910";
}

.sc-revert:before {
  content: "\e911";
}

.sc-rotate-left:before {
  content: "\e912";
}

.sc-rotate-right:before {
  content: "\e913";
}

.sc-search:before {
  content: "\e914";
}

.sc-time:before {
  content: "\e915";
}

.sc-reply:before {
  content: "\e95a";
}

.sc-upload:before {
  content: "\e969";
}

.sc-visibility-on:before {
  content: "\e916";
}

.sc-visibility-off:before {
  content: "\e917";
}

.sc-disable:before {
  content: "\e971";
}

.sc-calendar:before {
  content: "\e918";
}

.sc-select-all:before {
  content: "\e96a";
}

.sc-edit:before {
  content: "\e919";
}

.sc-comment:before {
  content: "\e91a";
}

.sc-archive:before {
  content: "\e91b";
}

.sc-unset:before {
  content: "\e91c";
}

.sc-delete:before {
  content: "\e91d";
}

.sc-approved:before {
  content: "\e9a3";
}

.sc-close:before {
  content: "\e990";
}

.sc-replied:before {
  content: "\e991";
}

.sc-history:before {
  content: "\e992";
}

.sc-percent:before {
  content: "\e98d";
}

.sc-promo-code:before {
  content: "\e9a1";
}

.sc-rotate:before {
  content: "\e91e";
}

.sc-options:before {
  content: "\e91f";
}

.sc-gallery-1:before {
  content: "\e920";
}

.sc-shopping-cart:before {
  content: "\e976";
}

.sc-agency:before {
  content: "\e921";
}

.sc-city-alerts:before {
  content: "\e961";
}

.sc-alerts:before {
  content: "\e922";
}

.sc-alerts-selected:before {
  content: "\e977";
}

.sc-block:before {
  content: "\e923";
}

.sc-favorite:before {
  content: "\e924";
}

.sc-favorite-selected:before {
  content: "\e978";
}

.sc-followers:before {
  content: "\e925";
}

.sc-followers-selected:before {
  content: "\e979";
}

.sc-forum:before {
  content: "\e926";
}

.sc-gallery:before {
  content: "\e927";
}

.sc-home:before {
  content: "\e928";
}

.sc-tickets:before {
  content: "\e99c";
}

.sc-instant:before {
  content: "\e929";
}

.sc-logout:before {
  content: "\e92a";
}

.sc-login:before {
  content: "\e993";
}

.sc-main:before {
  content: "\e92b";
}

.sc-manager:before {
  content: "\e92c";
}

.sc-payment:before {
  content: "\e92d";
}

.sc-ads:before {
  content: "\e9bf";
}

.sc-planner:before {
  content: "\e92e";
}

.sc-profile:before {
  content: "\e92f";
}

.sc-review:before {
  content: "\e930";
}

.sc-settings:before {
  content: "\e931";
}

.sc-statistic:before {
  content: "\e932";
}

.sc-newsletter:before {
  content: "\e9b5";
}

.sc-support:before {
  content: "\e933";
}

.sc-tours:before {
  content: "\e934";
}

.sc-verify:before {
  content: "\e935";
}

.sc-age-verify:before {
  content: "\e959";
}

.sc-new:before {
  content: "\e96b";
}

.sc-video:before {
  content: "\e972";
}

.sc-video-play:before {
  content: "\e98c";
}

.sc-new-esc:before {
  content: "\e97a";
}

.sc-independent:before {
  content: "\e988";
}

.sc-trans:before {
  content: "\e989";
}

.sc-boys:before {
  content: "\e995";
}

.sc-actions:before {
  content: "\e98a";
}

.sc-transfer:before {
  content: "\e9a4";
}

.sc-status:before {
  content: "\e99d";
}

.sc-report:before {
  content: "\e9a5";
}

.sc-link:before {
  content: "\e98b";
}

.sc-night:before {
  content: "\e97b";
}

.sc-vip:before {
  content: "\e97c";
}

.sc-dark-theme:before {
  content: "\e9c0";
}

.sc-light-theme:before {
  content: "\e9c1";
}

.sc-location:before {
  content: "\e97d";
}

.sc-like:before {
  content: "\e96c";
}

.sc-dislike:before {
  content: "\e96d";
}

.sc-phone:before {
  content: "\e95b";
}

.sc-sms:before {
  content: "\e962";
}

.sc-viber:before {
  content: "\e963";
}

.sc-whatsapp:before {
  content: "\e964";
}

.sc-telegram:before {
  content: "\e97e";
}

.sc-skype:before {
  content: "\e97f";
}

.sc-skype-icon:before {
  content: "\e980";
}

.sc-skype-online:before {
  content: "\e9b1";
}

.sc-skype-offline:before {
  content: "\e9b2";
}

.sc-skype-away:before {
  content: "\e9b3";
}

.sc-skype-dont-disturb:before {
  content: "\e9b4";
}

.sc-email:before {
  content: "\e98e";
}

.sc-language:before {
  content: "\e98f";
}

.sc-web-cam:before {
  content: "\e981";
}

.sc-public:before {
  content: "\e965";
}

.sc-private:before {
  content: "\e966";
}

.sc-ip:before {
  content: "\e9a6";
}

.sc-cookies:before {
  content: "\e9a7";
}

.sc-template:before {
  content: "\e9a8";
}

.sc-seo:before {
  content: "\e9a9";
}

.sc-earth:before {
  content: "\e9aa";
}

.sc-text-bold:before {
  content: "\e936";
}

.sc-text-underline:before {
  content: "\e937";
}

.sc-text-italic:before {
  content: "\e938";
}

.sc-info:before {
  content: "\e939";
}

.sc-attention:before {
  content: "\e93a";
}

.sc-move:before {
  content: "\e93b";
}

.sc-play:before {
  content: "\e93c";
}

.sc-rotatable:before {
  content: "\e93d";
}

.sc-diamond-premium:before {
  content: "\e93e";
}

.sc-diamond-light:before {
  content: "\e93f";
}

.sc-bronze-premium:before {
  content: "\e940";
}

.sc-silver-premium:before {
  content: "\e941";
}

.sc-gold-premium:before {
  content: "\e942";
}

.sc-trusted-member:before {
  content: "\e982";
}

.sc-junior-member:before {
  content: "\e983";
}

.sc-senior-member:before {
  content: "\e984";
}

.sc-diamond-member:before {
  content: "\e985";
}

.sc-vip-member:before {
  content: "\e986";
}

.sc-vip-plus-member:before {
  content: "\e987";
}

.sc-send:before {
  content: "\e9ab";
}

.sc-status-pending:before {
  content: "\e9ac";
}

.sc-status-sent:before {
  content: "\e9ad";
}

.sc-status-sending-failed:before {
  content: "\e9ae";
}

.sc-status-delivery-failed:before {
  content: "\e9af";
}

.sc-status-delivered:before {
  content: "\e9b0";
}

.sc-girl-of-day:before {
  content: "\e96e";
}

.sc-girl-of-month:before {
  content: "\e9a2";
}

.sc-boost:before {
  content: "\e96f";
}

.sc-bump:before {
  content: "\e970";
}

.sc-file-xlsx:before {
  content: "\e943";
}

.sc-file-xls:before {
  content: "\e944";
}

.sc-file-video:before {
  content: "\e945";
}

.sc-file-docx:before {
  content: "\e946";
}

.sc-file-doc:before {
  content: "\e947";
}

.sc-file-txt:before {
  content: "\e948";
}

.sc-file-pdf:before {
  content: "\e949";
}

.sc-file-image:before {
  content: "\e94a";
}

.sc-compare-up:before {
  content: "\e95c";
}

.sc-compare-down:before {
  content: "\e95d";
}

.sc-compare-equal:before {
  content: "\e95e";
}

.sc-modified:before {
  content: "\e9a0";
}

.sc-external-link:before {
  content: "\e99e";
}

.sc-start:before {
  content: "\e967";
}

.sc-finish:before {
  content: "\e968";
}

.sc-italian .path1:before {
  content: "\e94b";
  color: rgb(255, 255, 255);
}

.sc-italian .path2:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(0, 131, 255);
}

.sc-italian .path3:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.sc-italian .path4:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(247, 65, 65);
}

.sc-italian .path5:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(140, 199, 57);
}

.sc-english .path1:before {
  content: "\e950";
  color: rgb(255, 255, 255);
}

.sc-english .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(0, 131, 255);
}

.sc-english .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.sc-english .path4:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(247, 65, 65);
}

.sc-english .path5:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(247, 65, 65);
}

.sc-english .path6:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(247, 65, 65);
}

.sc-english .path7:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(247, 65, 65);
}

.sc-english .path8:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(247, 65, 65);
}

/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.sc-svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: currentColor;
  mask-size: contain;
  user-select: none;
  -webkit-mask-size: contain !important;
}
.sc-svg.sc-svg-whatsapp {
  -webkit-mask: url("/imgr/icons/whatsapp.svg") no-repeat center;
  mask: url("/imgr/icons/whatsapp.svg") no-repeat center;
}
.sc-svg.sc-svg-viber {
  -webkit-mask: url("/imgr/icons/viber.svg") no-repeat center;
  mask: url("/imgr/icons/viber.svg") no-repeat center;
}
.sc-svg.sc-svg-telegram {
  -webkit-mask: url("/imgr/icons/telegram.svg") no-repeat center;
  mask: url("/imgr/icons/telegram.svg") no-repeat center;
}
.sc-svg.sc-svg-signal {
  -webkit-mask: url("/imgr/icons/signal.svg") no-repeat center;
  mask: url("/imgr/icons/signal.svg") no-repeat center;
}
.sc-svg.sc-svg-view {
  -webkit-mask: url("/imgr/icons/view.svg") no-repeat center;
  mask: url("/imgr/icons/view.svg") no-repeat center;
}

.arial-va-20 {
  font-family: Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.02em !important;
  font-weight: 400 !important;
}

/*
  Link Style
*/
a {
  cursor: pointer;
  text-decoration: none;
  outline: none;
  font-weight: unset;
  color: #000000;
}
a:hover {
  color: black;
}
a:active {
  color: black;
}
a:not(.link-button).link-btn {
  text-transform: uppercase;
  cursor: pointer;
}
a:not(.link-button).link-white {
  color: #ffffff;
}
a:not(.link-button).link-white:hover {
  color: #f2f2f2;
}
a:not(.link-button).link-white:active {
  color: #e6e6e6;
}
a:not(.link-button).link-primary {
  color: #d61f79;
}
a:not(.link-button).link-primary:hover {
  color: #c01c6c;
}
a:not(.link-button).link-primary:active {
  color: #a91960;
}
a:not(.link-button).link-primary-bold {
  color: #b11060;
}
a:not(.link-button).link-primary-bold:hover {
  color: #9a0e53;
}
a:not(.link-button).link-primary-bold:active {
  color: #820c47;
}
a:not(.link-button).link-primary-light {
  color: #e44493;
}
a:not(.link-button).link-primary-light:hover {
  color: #e12e86;
}
a:not(.link-button).link-primary-light:active {
  color: #d61f79;
}
a:not(.link-button).link-danger {
  color: #ff4869;
}
a:not(.link-button).link-danger:hover {
  color: #ff2f54;
}
a:not(.link-button).link-danger:active {
  color: #ff153f;
}
a:not(.link-button).link-success {
  color: #02b10e;
}
a:not(.link-button).link-success:hover {
  color: #02980c;
}
a:not(.link-button).link-success:active {
  color: #017f0a;
}
a:not(.link-button).link-warning {
  color: #ffd514;
}
a:not(.link-button).link-warning:hover {
  color: #facd00;
}
a:not(.link-button).link-warning:active {
  color: #e0b800;
}
a:not(.link-button).link-gray {
  color: #949496;
}
a:not(.link-button).link-gray:hover {
  color: #878789;
}
a:not(.link-button).link-gray:active {
  color: #7a7a7d;
}
a[disabled]:not(a[disabled=false]) {
  cursor: default;
  color: #949496;
}

/*
  List Style
*/
/*
  Font Style
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  line-height: 1.17;
}

.fs-8 {
  font-size: calc(8px + var(--big-fs));
  line-height: 10px;
}

.fs-9 {
  font-size: calc(9px + var(--big-fs));
  line-height: 12px;
}

.fs-10 {
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}

.fs-11 {
  font-size: calc(11px + var(--big-fs));
  line-height: 14px;
}

.fs-12 {
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}

.fs-13 {
  font-size: calc(13px + var(--big-fs));
  line-height: 18px;
}

.fs-14 {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}

.fs-16 {
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}

.fs-18 {
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}

.fs-20 {
  font-size: calc(20px + var(--big-fs));
  line-height: 26px;
}

.fs-22 {
  font-size: calc(22px + var(--big-fs));
  line-height: 28px;
}

.fs-24 {
  font-size: calc(24px + var(--big-fs));
  line-height: 30px;
}

.fs-32 {
  font-size: calc(32px + var(--big-fs));
  line-height: 40px;
}

.fs-40 {
  font-size: calc(40px + var(--big-fs));
  line-height: 48px;
}

.text {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
  color: #000000;
}

.text-light {
  font-weight: 200;
}

.text-normal {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 600;
}

.text-ellipsis {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  word-break: break-all;
}
.text-ellipsis.line-1 {
  -webkit-line-clamp: 1;
}
.text-ellipsis.line-2 {
  -webkit-line-clamp: 2;
  word-break: break-word;
}
.text-ellipsis.line-3 {
  -webkit-line-clamp: 3;
  word-break: break-word;
}
.text-ellipsis.line-4 {
  -webkit-line-clamp: 4;
  word-break: break-word;
}
.text-ellipsis.line-5 {
  -webkit-line-clamp: 5;
  word-break: break-word;
}
.text-ellipsis.line-6 {
  -webkit-line-clamp: 6;
  word-break: break-word;
}
.text-ellipsis.line-7 {
  -webkit-line-clamp: 7;
  word-break: break-word;
}
.text-ellipsis.line-8 {
  -webkit-line-clamp: 8;
  word-break: break-word;
}
.text-ellipsis.line-9 {
  -webkit-line-clamp: 9;
  word-break: break-word;
}
.text-ellipsis.line-10 {
  -webkit-line-clamp: 10;
  word-break: break-word;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.text-hover-underline:hover {
  text-decoration: underline;
}

.text-primary {
  color: #d61f79;
}

.text-primary-light {
  color: #e44493;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-white {
  color: #ffffff;
}

.text-gray {
  color: #949496;
}

.text-light-gray {
  color: #f0f0f0;
}

.text-info {
  color: #2c73ff;
}

.text-success {
  color: #02b10e;
}

.text-warning {
  color: #ffd514;
}

.text-danger {
  color: #ff4869;
}

.text-black {
  color: #000000;
}

.no-wrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.no-pointer-event {
  pointer-events: none;
}

.word-break {
  word-break: break-word;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.px-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.py-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.m-1 {
  margin: 1px !important;
}

.p-1 {
  padding: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.px-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.my-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.m-2 {
  margin: 2px !important;
}

.p-2 {
  padding: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.mx-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.px-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.my-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.m-3 {
  margin: 3px !important;
}

.p-3 {
  padding: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.m-4 {
  margin: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.mx-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.px-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.my-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.m-6 {
  margin: 6px !important;
}

.p-6 {
  padding: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.m-8 {
  margin: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-12 {
  margin: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.m-14 {
  margin: 14px !important;
}

.p-14 {
  padding: 14px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.m-16 {
  margin: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.my-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.py-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.m-17 {
  margin: 17px !important;
}

.p-17 {
  padding: 17px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.mx-18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.px-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.my-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.py-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.m-18 {
  margin: 18px !important;
}

.p-18 {
  padding: 18px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.mx-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.my-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.py-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.m-22 {
  margin: 22px !important;
}

.p-22 {
  padding: 22px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.m-24 {
  margin: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.mt-26 {
  margin-top: 26px !important;
}

.pt-26 {
  padding-top: 26px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.pl-26 {
  padding-left: 26px !important;
}

.mr-26 {
  margin-right: 26px !important;
}

.pr-26 {
  padding-right: 26px !important;
}

.mx-26 {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.px-26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.my-26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.py-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.m-26 {
  margin: 26px !important;
}

.p-26 {
  padding: 26px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.m-28 {
  margin: 28px !important;
}

.p-28 {
  padding: 28px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.mx-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.m-32 {
  margin: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.pt-34 {
  padding-top: 34px !important;
}

.mb-34 {
  margin-bottom: 34px !important;
}

.pb-34 {
  padding-bottom: 34px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.pl-34 {
  padding-left: 34px !important;
}

.mr-34 {
  margin-right: 34px !important;
}

.pr-34 {
  padding-right: 34px !important;
}

.mx-34 {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.px-34 {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.my-34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.py-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.m-34 {
  margin: 34px !important;
}

.p-34 {
  padding: 34px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.mx-36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.px-36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.m-36 {
  margin: 36px !important;
}

.p-36 {
  padding: 36px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.pt-38 {
  padding-top: 38px !important;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.pb-38 {
  padding-bottom: 38px !important;
}

.ml-38 {
  margin-left: 38px !important;
}

.pl-38 {
  padding-left: 38px !important;
}

.mr-38 {
  margin-right: 38px !important;
}

.pr-38 {
  padding-right: 38px !important;
}

.mx-38 {
  margin-left: 38px !important;
  margin-right: 38px !important;
}

.px-38 {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.my-38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}

.py-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.m-38 {
  margin: 38px !important;
}

.p-38 {
  padding: 38px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.pt-42 {
  padding-top: 42px !important;
}

.mb-42 {
  margin-bottom: 42px !important;
}

.pb-42 {
  padding-bottom: 42px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.pl-42 {
  padding-left: 42px !important;
}

.mr-42 {
  margin-right: 42px !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.mx-42 {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

.px-42 {
  padding-left: 42px !important;
  padding-right: 42px !important;
}

.my-42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.py-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}

.m-42 {
  margin: 42px !important;
}

.p-42 {
  padding: 42px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.mx-44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.px-44 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.m-44 {
  margin: 44px !important;
}

.p-44 {
  padding: 44px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.pt-46 {
  padding-top: 46px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.pb-46 {
  padding-bottom: 46px !important;
}

.ml-46 {
  margin-left: 46px !important;
}

.pl-46 {
  padding-left: 46px !important;
}

.mr-46 {
  margin-right: 46px !important;
}

.pr-46 {
  padding-right: 46px !important;
}

.mx-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

.px-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.my-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

.py-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

.m-46 {
  margin: 46px !important;
}

.p-46 {
  padding: 46px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.mx-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.m-48 {
  margin: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.m-60 {
  margin: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.pt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.pb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.pl-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.pr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-x: auto !important;
}

.px-auto {
  margin-x: auto !important;
}

.my-auto {
  margin-y: auto !important;
}

.py-auto {
  margin-y: auto !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-inherit {
  width: inherit;
}

.content-fit {
  width: fit-content !important;
}

.h-100 {
  height: 100%;
}

.bordered {
  border: 1px solid rgba(236, 236, 236, 0.5);
  box-sizing: border-box;
}

.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.justify-center {
  justify-content: center;
}

.justify-items-center {
  justify-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.transform-none {
  transform: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}

.hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 30px 0;
}

.sc-alert {
  padding: 16px;
  border-radius: 6px;
}
.sc-alert .title {
  display: flex;
  align-items: center;
  justify-content: left;
  color: currentColor;
  margin-bottom: 10px;
  font-weight: 500;
}
.sc-alert.warning {
  background: #fff1e1;
}
.sc-alert.warning .title {
  color: #ffd514;
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .visible-sm {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .visible-md {
    display: none !important;
  }
}
@media (max-width: 1207px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1208px) {
  .visible-lg {
    display: none !important;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.banners-page-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(147px, 1fr));
  grid-gap: 6px;
  margin-top: 20px;
}
.banners-page-container a.ixsHitBtn {
  border: 1px solid #f0f0f0;
}
@media (max-width: 374px) {
  .banners-page-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.aside-banners-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;
}
.aside-banners-list .ixsHitBtn:not(.half) {
  grid-column: 1/-1;
}

a.ixsHitBtn {
  display: flex;
  position: relative;
}
a.ixsHitBtn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

* {
  margin: 0;
  padding: 0;
  font-family: Tahoma, Roboto, Arial, Verdana, Georgia, Helvetica, sans-serif;
}

:root {
  --big-fs: 0px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

ul {
  list-style: none;
}

[hidden]:not([hidden=false]),
.hidden {
  display: none !important;
}

.grecaptcha-badge {
  display: none;
}

.ui-icon {
  background-image: none;
}

.highslide img {
  border: none !important;
}

.sc-msg.search input {
  width: 100%;
}

.go-to-top {
  position: fixed;
  left: 35px;
  bottom: 110px;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.8);
}
.go-to-top i {
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 36px;
  line-height: 1;
}
.go-to-top span {
  position: relative;
  top: 9px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
@media (max-width: 1024px) {
  .go-to-top {
    right: 16px !important;
    left: unset !important;
    bottom: var(--sc-gt-mb) !important;
  }
}

.classified-ads .list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  grid-gap: 16px;
}
.classified-ads .list .list-item {
  display: grid;
  grid-template-columns: auto 1fr;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  height: 120px;
  overflow: hidden;
}
.classified-ads .list .list-item > img {
  width: 80px;
  height: inherit;
  object-fit: cover;
}
.classified-ads .list .list-item .content {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.classified-ads .list .list-item .content img {
  filter: brightness(0.5);
}
.classified-ads .list .list-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
@media (max-width: 480px) {
  .classified-ads .list {
    grid-template-columns: 1fr;
  }
}

.listing-bottom-banners {
  display: grid;
  margin: 20px 0;
  grid-gap: 6px;
  grid-template-columns: 218px 1fr;
}
.listing-bottom-banners .ixsHitBtn.bottom {
  grid-column: 1/-1;
}
.listing-bottom-banners .ixsHitBtn.bottom_left {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 2;
}
.listing-bottom-banners .ixsHitBtn.bottom_right {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-column-end: 3;
}

.list-types {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.list-types.grid-view a[data-mode=grid], .list-types.xl-view a[data-mode=xl], .list-types.list-view a[data-mode=list] {
  background-color: #d61f79;
  color: #ffffff;
}
.list-types a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #d61f79;
  width: 34px;
  height: 34px;
}
@media (max-width: 1024px) {
  .list-types a {
    border: 1px solid #d61f79;
  }
}
.list-types .sc-and6 {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}

.sc-subheader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 30px 20px;
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 36px;
  color: #000000;
  flex-wrap: wrap;
}
.sc-subheader.no-border {
  border-bottom: none;
}
.sc-subheader h1 {
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
  font-family: Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.02em !important;
  font-weight: 400 !important;
}
.sc-subheader .sc {
  font-size: calc(24px + var(--big-fs));
  line-height: 1;
}
@media (max-width: 768px) {
  .sc-subheader {
    margin-left: -16px;
    margin-right: -16px;
    padding: 20px 16px;
  }
  .sc-subheader .btn {
    width: 100%;
    flex-basis: 100%;
  }
}

.sc-border-title {
  position: relative;
  color: #000000;
  font-weight: bold;
  overflow: hidden;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
.sc-border-title:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  margin: auto 0 auto 9px;
  background-color: #f0f0f0;
}

.splash-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1111;
  width: auto;
  height: auto;
}
.splash-container .splash-content {
  margin: auto;
  width: 636px;
  height: 452px;
  position: relative;
}
.splash-container .splash-content .desktop-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.splash-container .splash-content .description {
  display: flex;
  width: 325px;
  height: 100%;
  padding: 130px 40px 40px;
  position: relative;
  grid-gap: 10px;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
}
.splash-container .splash-content .description p {
  margin-bottom: 20px;
  overflow-y: auto;
}
.splash-container .splash-content .description p::-webkit-scrollbar {
  width: 4px;
  border-radius: 3px;
  background-color: rgba(214, 31, 121, 0.2);
}
.splash-container .splash-content .description p::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d61f79;
}
.splash-container .splash-content .description .btn {
  width: 100%;
  height: 34px;
  padding: 4px;
}
@media screen and (max-height: 500px) {
  .splash-container {
    overflow: scroll;
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .splash-container {
    overflow: scroll;
    padding: 60px 20px;
  }
  .splash-container .splash-content {
    width: unset;
    height: unset;
    border-radius: 20px;
    background-color: #000000;
  }
  .splash-container .splash-content .mobile-bg-top,
  .splash-container .splash-content .mobile-bg-bottom {
    max-width: 335px;
    width: 100%;
  }
  .splash-container .splash-content .description {
    max-width: 335px;
    width: 100%;
    margin-bottom: 0;
    padding: 100px 40px 0;
    min-height: 415px;
    grid-gap: 20px;
  }
  .splash-container .splash-content .mobile-bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .splash-container .splash-content .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px !important;
  }
}

.pswp__button i {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
  font-size: 32px;
}

sc-mobile-toggle .mobile-toggle {
  margin-bottom: var(--sc-msg-mobile-mb) !important;
}
